import React from 'react';
import '../../../stylesheets/ProjectsHead.css'

const ProjectsHead = () => {
  return (
  <div className="projects-head">
    <div className="projects-head-image">
        <h1>PROJECTS</h1>
    </div>
  </div>
  );
};

export default ProjectsHead;