import React from 'react';
import OurServicesHead from './components/services/OurServicesHead';
import OurServicesBody from './components/services/OurServicesBody';

const OurServices = () => {
  return (
    <div>
      <OurServicesHead />
      <OurServicesBody />
    </div>
  );
};

export default OurServices;
